import React, { useState, useEffect, useRef, useLayoutEffect, useMemo } from 'react';
import { PersonOutlineOutlined } from '@material-ui/icons';
import { CircularProgress, Button, Grid } from '@material-ui/core';
import { GET_LIST, useQueryWithStore, useDataProvider } from 'react-admin';
import { Group, GroupContent, GroupItem, GroupTitle } from 'components/common/Group';
import { ToLocation } from 'icons';
import { CountriesSelect, Input, StateSelect } from 'components/form';
import { emailOptional, required, requiredIf, maxLength } from 'components/form/validationRules';
import AddressDistributor from 'components/form/AddressDistributor';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SuggestibleTextInput from '../../calculator/components/zip-input/SuggestibleTextInput';
import { doUpdateRecipients } from '../../../services/helpers/contactsHelpers';
import NameOptionsList from './NameOptionsList';
import TaxIdInput from 'components/customs/TaxIdInput';
import { getDomesticCountryCode } from 'services/helpers/countryCode';
let debouncedSearch;

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.contrastText,
    border: `1px solid #E0E0E0`,
  },
  secondaryText: {
    color: theme.palette.secondary.contrastText,
  },
  option: {
    padding: theme.spacing(0.75),
    overflow: 'hidden',
    width: '100%',
  },
  dummyOption: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: 'transparent !important',
    cursor: 'inherit',
  },
  optionItem: {
    padding: theme.spacing(0),
    '& > li': {
      '&:not(:last-child)': {
        borderBottom: `1px solid #E0E0E0`,
      },
      '&:last-child': {
        borderBottom: `1px solid transparent`,
      },
    },
  },
}));

const RecipientGroup = (props) => {
  const classes = useStyles();
  const { isAdjustForm, form } = props;

  if (!props?.values?.taxIds?.RECEIVER?.taxIdType) {
    form.change('taxIds.RECEIVER.taxIdType', 'VAT');
  }

  const userName = props?.values?.shippingAddress?.name ?? '';

  const [showParser, setShowParser] = useState(false);
  const [recipients, setRecipients] = useState<any>([]);
  const [isRecipientsLoading, setIsRecipientsLoading] = useState<any>(false);
  const [isRecipientsError, setIsRecipientsError] = useState<any>(false);
  const [currentSearchString, setCurrentSearchString] = useState<string>('');
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const [taxNumberTypes, setTaxNumberTypes] = useState<any>([]);

  const dataProvider = useDataProvider();
  const hiddenInputRef = useRef(null);

  useEffect(() => {
    const newName = userName ?? '';
    if (debouncedSearch) {
      clearTimeout(debouncedSearch);
      debouncedSearch = undefined;
    }

    if (newName.length >= 2) {
      debouncedSearch = setTimeout(() => {
        updateRecipients(newName);
      }, 600);
    } else {
      updateRecipients(newName);
    }
  }, [userName]); //eslint-disable-line

  useEffect(() => {
    dataProvider
      .getList('taxIdTypes')
      .then(({ data }) => {
        setTaxNumberTypes(() => data);
      })
      .catch((error) => console.log(error));
  }, [dataProvider]);

  //eslint-disable-next-line
  useLayoutEffect(() => {
    if (hiddenInputRef?.current) {
      setIsNameError(
        !!hiddenInputRef.current?.children?.[0]?.children?.[0]?.children?.[0]?.classList?.contains(
          'Mui-error'
        )
      );
    }
  }); //eslint-disable-line

  const updateRecipients = (searchString: string): void => {
    doUpdateRecipients(
      searchString,
      setCurrentSearchString,
      setRecipients,
      setIsRecipientsError,
      setIsRecipientsLoading,
      dataProvider
    );
  };

  const optionsExist = recipients.length > 0;
  const finalOptions = optionsExist
    ? recipients
    : (currentSearchString ?? '')?.length < 2
    ? []
    : [
        {
          name: userName ?? '',
          dummy: true,
          elementToRender: isRecipientsLoading
            ? 'Loading...'
            : isRecipientsError
            ? 'Unable to retrieve recipients'
            : 'No options found',
        },
      ];

  const handleInputChange = (event, value) => {
    form.change('shippingAddress.name', value);
    form.change('recipientId', undefined);
  };

  const handleChange = (event, value) => {
    form.batch(() => {
      form.change('shippingAddress.name', value.name);
      form.change('shippingAddress.company', value.company);
      form.change('shippingAddress.phone', value.phone);
      form.change('email', value.email);
      form.change('shippingAddress.postalCode', value.postalCode);
      form.change('shippingAddress.country', value.country);
      form.change('shippingAddress.state', value.state);
      form.change('shippingAddress.city', value.city);
      form.change('shippingAddress.addressLine1', value.addressLine1);
      form.change('shippingAddress.addressLine2', value.addressLine2);
      form.change('recipientId', value.id);
      form.change('taxIds.RECEIVER.taxIdType', value.taxIdType);
      form.change('taxIds.RECEIVER.taxId', value.taxId);
      form.change('shippingAddress.taxId', value.taxId);
      form.change('shippingAddress.taxIdType', value.taxIdType);
    });
  };

  const renderOption = (option) => {
    if (option.dummy) {
      return <div className={classes.option}>{option?.elementToRender ?? ''}</div>;
    }

    const nameTokens = (option?.name ?? '').split(' ');
    let firstName = nameTokens?.length === 2 ? nameTokens[0] : option?.name ?? '';
    let lastName = nameTokens?.length === 2 ? nameTokens[1] : '';

    return (
      <div className={classes.option}>
        {firstName}
        {!!lastName?.length && <b> {lastName}</b>}{' '}
        <span className={classes.secondaryText}>
          ({option.state}, {option.postalCode})
        </span>
      </div>
    );
  };

  const renderInput = (props) => (
    <SuggestibleTextInput
      {...props}
      isRecipientSelector
      label={'Full name'}
      inputProps={{
        ...props.inputProps,
        autoComplete: 'disabled',
      }}
      error={isNameError}
      helperText={isNameError ? 'Name is required' : undefined}
    />
  );

  const getTypeOptions = (allTaxNumbers) =>
    allTaxNumbers?.map((type) => ({
      value: type.taxIdType,
      label: type.taxIdType,
    }));

  return (
    <Group>
      <GroupTitle icon={<PersonOutlineOutlined />}>
        <Grid container justify={'space-between'} alignItems={'flex-start'}>
          <Grid item>Recipient</Grid>
          <Grid item>
            <Button
              size="small"
              className={classes.button}
              endIcon={showParser ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              onClick={() => setShowParser(!showParser)}>
              Paste US Address
            </Button>
          </Grid>
        </Grid>
      </GroupTitle>
      <GroupContent>
        {showParser && (
          <GroupItem wide>
            <Input name="string" label="Paste or type US address here..." multiline={true} />
            <AddressDistributor
              field="string"
              targets={[
                'shippingAddress.name',
                'shippingAddress.addressLine1',
                'shippingAddress.addressLine2',
                'shippingAddress.city',
                'shippingAddress.state',
                'shippingAddress.postalCode',
                'shippingAddress.company',
              ]}
            />
          </GroupItem>
        )}
        <GroupItem wide={!isAdjustForm}>
          <Autocomplete
            disableClearable
            classes={{ option: optionsExist ? undefined : classes.dummyOption }}
            forcePopupIcon={false}
            options={finalOptions}
            value={userName ?? ''}
            inputValue={userName ?? ''}
            getOptionLabel={(option) => option?.name ?? option ?? ''}
            filterOptions={(options) => options}
            renderOption={renderOption}
            renderInput={renderInput}
            onInputChange={handleInputChange}
            onChange={handleChange}
            freeSolo
            ListboxProps={{ className: classes.optionItem }}
            PaperComponent={({ children, ...props }) => (
              <NameOptionsList {...props}>{children}</NameOptionsList>
            )}
          />
        </GroupItem>
        <div style={{ display: 'none' }} ref={hiddenInputRef}>
          <Input label="" name="shippingAddress.name" validate={required} />
        </div>
        <GroupItem>
          <Input label="Company Name (optional)" name="shippingAddress.company" />
        </GroupItem>
        <GroupItem>
          <Input label="Phone (optional)" name="shippingAddress.phone" validate={maxLength(50)} />
        </GroupItem>
        <GroupItem>
          <Input label="Email (optional)" name="email" validate={emailOptional} />
        </GroupItem>
        <GroupItem>
          <div style={{ marginTop: 16 }}>
            <TaxIdInput
              taxNumberTypes={taxNumberTypes}
              options={getTypeOptions(taxNumberTypes)}
              label="Tax ID"
              inputLabel="Tax number"
              sourceName="taxIds.RECEIVER"
            />
          </div>
        </GroupItem>
      </GroupContent>
    </Group>
  );
};

const AddressGroup = (props) => {
  const { isAdjustForm, form, values } = props;

  const domesticCountryCode = getDomesticCountryCode();

  const {
    data: countries,
    loading,
    error,
  } = useQueryWithStore({
    type: GET_LIST,
    resource: 'countries',
    payload: { pagination: { perPage: 999 } },
  });

  const countryCode = useMemo(() => {
    const currentCode = props?.values?.shippingAddress?.country;
    if (!currentCode) return '';
    if (countries && currentCode?.length > 2) {
      const code = countries?.find(
        (country) => country.name.toLowerCase() === currentCode.toLowerCase()
      ).code;
      form.change('shippingAddress.country', code);
      return code;
    }
    return currentCode;
  }, [props, countries]); //eslint-disable-line

  useEffect(() => {
    if (form) {
      form.blur('shippingAddress.postalCode');
    }
  }, [countryCode]); //eslint-disable-line

  if (error) {
    return <p>error</p>;
  }

  if (loading) {
    return <CircularProgress />;
  }

  const hasPostalCodes = (code) => {
    if (!code) {
      return false;
    }

    const country = countries.filter((c) => c.code === code);
    return country[0]?.postalCodeRequired;
  };

  return (
    <Group>
      <GroupTitle icon={<ToLocation />}>Address</GroupTitle>
      <GroupContent>
        <GroupItem wide={!isAdjustForm}>
          <Input label="Street Address 1" name="shippingAddress.addressLine1" validate={required} />
        </GroupItem>
        <GroupItem wide={!isAdjustForm}>
          <Input label="Street Address 2 (optional)" name="shippingAddress.addressLine2" />
        </GroupItem>
        <GroupItem>
          <Input label="City / Town" name="shippingAddress.city" validate={required} />
        </GroupItem>
        <GroupItem>
          <StateSelect
            label="State"
            name="shippingAddress.state"
            countryCode={countryCode}
            validate={
              values?.shippingAddress?.country === domesticCountryCode ? required : () => undefined
            }
          />
        </GroupItem>
        <GroupItem>
          <Input
            label={hasPostalCodes(countryCode) ? 'ZIP' : 'ZIP (optional)'}
            name="shippingAddress.postalCode"
            validate={requiredIf(() => hasPostalCodes(countryCode))}
            key={countryCode}
          />
        </GroupItem>
        <GroupItem>
          <CountriesSelect
            label="Country"
            name="shippingAddress.country"
            disabled={isAdjustForm}
            validate={required}
          />
        </GroupItem>
      </GroupContent>
    </Group>
  );
};

const BuyerForm = (props) => {
  return (
    <>
      <RecipientGroup {...props} />
      <AddressGroup {...props} />
    </>
  );
};

export default BuyerForm;
