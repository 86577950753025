import React, { useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';

import useAsyncTask from 'hooks/useAsyncTask';
import BaseMessageView from './BaseMessageView';
import successImage from './success.svg';
import errorImage from './error.svg';

const ConfirmSignUp = ({ notLoggedIn = false }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const username = urlParams.get('username');
  const confirmationCode = urlParams.get('code');
  const email = urlParams.get('email');

  const [resendEmail, resendingEmailResult] = useAsyncTask((username: string) => {
    return Auth.resendSignUp(username, { type: 'signup' });
  });

  const [confirmSignUp, confirmationResult] = useAsyncTask(
    (username: string, confirmationCode: string) => {
      return Auth.confirmSignUp(username, confirmationCode).then(() => {});
    }
  );

  const handleResend = () => {
    if (!username) {
      return;
    }

    resendEmail(username);
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    if (notLoggedIn) {
      const encodeEmail = encodeURIComponent(email as unknown as string);
      window.location.href = `/?email=${encodeEmail}`;
    } else {
      window.location.href = `/`;
    }
  };

  useEffect(() => {
    const processVerification = async () => {
      if (!username || !confirmationCode) {
        return;
      }

      await Auth.signOut();

      // Remove items from local storage that include 'CognitoIdentityServiceProvider' in their name
      Object.keys(window.localStorage).forEach((key) => {
        if (key.includes('CognitoIdentityServiceProvider')) {
          localStorage.removeItem(key);
        }
      });

      confirmSignUp(username, confirmationCode);
    };

    processVerification();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (confirmationResult.status === 'SUCCESS') {
      const analytics = getAnalytics();
      logEvent(analytics, 'registration_verification');

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'registration_verification' });
    }
  }, [confirmationResult]);

  if (!username || !confirmationCode) {
    return (
      <BaseMessageView
        title="Bad Verification URL"
        image={errorImage}
        message="Please check your copied link or create a new one."
        action="Resend Verification Email"
        processing={false}
        onAction={handleResend}
        onSignIn={notLoggedIn ? handleSignIn : undefined}
        widthLimit={!notLoggedIn ? 800 : undefined}
      />
    );
  }

  if (confirmationResult.status === 'PROGRESS' || resendingEmailResult.status === 'PROGRESS') {
    return (
      <BaseMessageView
        title="Please Wait"
        message=""
        action="Resend Verification Email"
        processing={true}
        onAction={handleResend}
        onSignIn={notLoggedIn ? handleSignIn : undefined}
        widthLimit={!notLoggedIn ? 800 : undefined}
      />
    );
  }

  if (resendingEmailResult.status === 'FAILED') {
    return (
      <BaseMessageView
        title="Insufficient Error"
        image={errorImage}
        message={resendingEmailResult.value.message}
        action="Resend Verification Email"
        processing={false}
        onAction={handleResend}
        onSignIn={notLoggedIn ? handleSignIn : undefined}
        widthLimit={!notLoggedIn ? 800 : undefined}
      />
    );
  }

  if (resendingEmailResult.status === 'SUCCESS') {
    return (
      <BaseMessageView
        title="Check Your Email Inbox"
        message="We sent an email to verify your account."
        action="Resend Verification Email"
        processing={false}
        onAction={handleResend}
        onSignIn={notLoggedIn ? handleSignIn : undefined}
        widthLimit={!notLoggedIn ? 800 : undefined}
      />
    );
  }

  if (confirmationResult.status === 'FAILED') {
    return (
      <BaseMessageView
        title="Verification Error"
        image={errorImage}
        message={confirmationResult.value.message}
        action="Resend Verification Email"
        processing={false}
        onAction={handleResend}
        onSignIn={notLoggedIn ? handleSignIn : undefined}
        widthLimit={!notLoggedIn ? 800 : undefined}
      />
    );
  }

  if (confirmationResult.status === 'SUCCESS') {
    return (
      <BaseMessageView
        title="Verification Completed"
        image={successImage}
        message={`Thank you, your ${!notLoggedIn ? 'new ' : ''}account has been verified.`}
        action={!notLoggedIn ? 'OK' : 'Sign In'}
        processing={false}
        onAction={handleSignIn}
        widthLimit={!notLoggedIn ? 800 : undefined}
      />
    );
  }

  return null;
};

export default ConfirmSignUp;
