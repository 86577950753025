import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider } from 'react-admin';
import { Form } from 'react-final-form';
import { FormApi } from 'final-form';
import { FORM_ERROR } from 'final-form';
import classNames from 'classnames';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';

import { Dialog } from 'components/common';
import { FormErrorLabel, Input } from 'components/form';
import SubmitButton from 'components/buttons/SubmitButton';

import { required } from 'components/form/validationRules';
import useCustomNotify from 'hooks/useCustomNotify';
import {
  closeVerification,
  setVerificationClosed,
  setVerifiedPhone,
} from 'store/actions/verificationActions';
import useProfile from 'hooks/useProfile';

const useStyles = makeStyles((theme) => ({
  codeInput: {
    width: 80,
    '& input': {
      paddingRight: 5,
      textAlign: 'center',
      fontSize: 28,
    },
  },
  codeInputError: {
    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline:after': {
      borderBottomColor: theme.palette.error.main,
      '&:hover': {
        borderBottomColor: theme.palette.error.main,
      },
    },
    '& .MuiInputBase-root.MuiInput-root.MuiInput-underline:before': {
      borderBottomColor: theme.palette.error.main,
      '&:hover': {
        borderBottomColor: theme.palette.error.main,
      },
    },
  },
  phoneInput: {
    '& input': {
      fontSize: 22,
    },
  },
  codeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submitButton: {
    margin: theme.spacing(3, 0),
    textTransform: 'none',
  },
  timerNote: {
    margin: theme.spacing(4, 0, 1),
  },
  subheader: {
    marginBottom: theme.spacing(2),
  },
  codeLabel: {
    margin: theme.spacing(4, 0, 2),
    fontWeight: 700,
  },
  activeResend: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

type FormValues = {
  phoneNumber: string;
  oneTimePassword: null[] | string[];
  codeSent: boolean;
};

let intervalId;
const emptyOtpPassword = [null, null, null, null];

const PhoneVerificationDialog = () => {
  const classes = useStyles();

  const dataProvider = useDataProvider();
  const { getProfile, loading, profile } = useProfile();

  const prevPhoneRef = useRef<string | null>(null);
  const otpInputs = useRef<HTMLInputElement[]>([]);
  const formRef = useRef<FormApi<FormValues>>();

  const [time, setTime] = useState<number>(-1);

  const dispatch = useDispatch();
  const open = useSelector((state) => state.verification.dialogOpen);
  const newPhoneNumber = useSelector((state) => state.verification.newPhoneNumber);

  const notify = useCustomNotify();

  const fetchProfile = async () => {
    await getProfile();
  };

  useEffect(() => {
    if (!open || profile) return;

    fetchProfile();
  }, [open]); // eslint-disable-line

  useEffect(() => {
    if (time === 0) {
      clearInterval(intervalId);
    } else {
      formRef.current?.change('codeSent', true);
    }
  }, [time]);

  useEffect(() => {
    if (newPhoneNumber && newPhoneNumber === prevPhoneRef.current) {
      formRef.current?.change('phoneNumber', newPhoneNumber);
    }
  }, [newPhoneNumber]);

  const handleClose = (submitting: boolean, codeVerified: boolean) => {
    if (submitting) return;

    localStorage.setItem('verificationClosed', JSON.stringify(!codeVerified));
    dispatch(setVerificationClosed(!codeVerified));

    const phoneValue = formRef.current?.getFieldState('phoneNumber')?.value;
    prevPhoneRef.current = phoneValue;

    if (codeVerified) {
      sessionStorage.setItem('phoneVerified', JSON.stringify(codeVerified));
    }

    dispatch(setVerifiedPhone(codeVerified ? phoneValue : ''));
    dispatch(closeVerification());
  };

  const sendVerificationCode = async (values, form) => {
    try {
      const { data } = await dataProvider.get('phoneVerification', {
        phoneNumber: values.phoneNumber,
      });

      setTime(60);

      intervalId = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);

      form.batch(() => {
        form.change('oneTimePassword', [null, null, null, null]);
        form.change('codeSent', true);
      });

      return data;
    } catch (error: any) {
      return { [FORM_ERROR]: error.message };
    }
  };

  const verifyCode = async (values, form) => {
    try {
      if (values.oneTimePassword?.some((item) => !item)) {
        throw new Error('Require 4-digit confirmation code');
      }

      const { data } = await dataProvider.post('phoneVerification', {
        data: {
          phoneNumber: values.phoneNumber,
          oneTimePassword: values.oneTimePassword.join(''),
        },
      });

      handleClose(false, true);

      clearInterval(intervalId);
      prevPhoneRef.current = null;

      notify('Verification is completed successfully.', 'success');

      return data;
    } catch (error: any) {
      form.change('oneTimePassword', [null, null, null, null]);
      return { [FORM_ERROR]: error.message };
    }
  };

  const handleSubmitForm = (values, form) => {
    return values.codeSent ? verifyCode(values, form) : sendVerificationCode(values, form);
  };

  const timerPass = time === 0;
  if (timerPass) clearInterval(intervalId);

  if (!open) return null;

  return (
    <Form<FormValues>
      initialValues={{
        phoneNumber: profile?.phone_number ?? '',
        oneTimePassword: emptyOtpPassword,
        codeSent: false,
      }}
      keepDirtyOnReinitialize
      onSubmit={handleSubmitForm}
      render={({ handleSubmit, submitting, form, values, modified, ...renderProps }) => {
        formRef.current = form;

        const currentPhoneNumber = newPhoneNumber ?? profile?.phone_number;

        if (modified?.phoneNumber) {
          clearInterval(intervalId);

          form.change('codeSent', false);
          form.resetFieldState('phoneNumber');
        }

        if (currentPhoneNumber !== prevPhoneRef.current) {
          clearInterval(intervalId);

          form.change('phoneNumber', currentPhoneNumber);
          prevPhoneRef.current = currentPhoneNumber;
        }

        const handleChange = (value, index) => {
          const updatedOtp: any = [...(values.oneTimePassword ?? [])];

          if (!updatedOtp[index] || !value) {
            updatedOtp[index] = value;
          }

          form.change('oneTimePassword', updatedOtp);

          if (value !== '' && index < values?.oneTimePassword?.length - 1) {
            otpInputs.current[index + 1].focus();
          }
        };

        const handleKeyDown = (e, index) => {
          if (!values?.oneTimePassword) return;

          if (e.key === 'Backspace' && values?.oneTimePassword[index] === '' && index > 0) {
            otpInputs.current[index - 1].focus();
            const updatedOtp: any = [...values.oneTimePassword];
            updatedOtp[index - 1] = '';

            form.change('oneTimePassword', updatedOtp);
          }
        };

        const handleResendCode = () => {
          sendVerificationCode(values, form);
        };

        const handlePaste = (e) => {
          e.preventDefault();
          const value = e.clipboardData.getData('text');
          const valueLength = value.length;
          const updatedOtp = [...value?.split(''), ...emptyOtpPassword];

          if (valueLength < 4) {
            otpInputs.current[valueLength].focus();
          }

          form.change('oneTimePassword', updatedOtp.slice(0, 4));
        };

        const codeError = renderProps.submitError?.includes('confirmation');

        return (
          <Dialog
            title="Verify your phone number"
            open={open}
            onCancel={() => handleClose(submitting, false)}
            showActionBtns={false}>
            <form>
              <Typography color="textSecondary" className={classes.subheader}>
                Enter a mobile phone number you would like to use for
                <br />
                SMS verification:
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <Input
                  name="phoneNumber"
                  validate={required}
                  label="Phone"
                  classes={{ root: classes.phoneInput }}
                />
              )}
              {values.codeSent ? (
                <>
                  <Typography className={classes.codeLabel}>Enter the code</Typography>
                  <div className={classes.codeContainer}>
                    {values?.oneTimePassword?.map((item, index) => (
                      <Input
                        name={`oneTimePassword[${index}]`}
                        classes={{
                          root: classNames(classes.codeInput, codeError && classes.codeInputError),
                        }}
                        onChange={(value) => handleChange(value, index)}
                        inputProps={{
                          inputRef: (input) => (otpInputs.current[index] = input),
                          onKeyDown: (e) => handleKeyDown(e, index),
                          onPaste: handlePaste,
                        }}
                      />
                    ))}
                  </div>
                  <Typography color="textSecondary" className={classes.timerNote}>
                    Didn’t get a code?{' '}
                    {timerPass ? (
                      <span onClick={handleResendCode} className={classes.activeResend}>
                        Resend
                      </span>
                    ) : (
                      <span>Resend ({time} sec)</span>
                    )}
                  </Typography>
                </>
              ) : (
                <Typography color="textSecondary" className={classes.timerNote}>
                  You’ll receive a 4 digit verification code
                </Typography>
              )}
              <FormErrorLabel {...renderProps} />
              <SubmitButton
                color="primary"
                size="large"
                label={values.codeSent ? 'Submit' : 'Send verification code'}
                submitting={submitting}
                disabled={submitting}
                className={classes.submitButton}
                onClick={(values) => handleSubmit(values, form)}
                fullWidth
              />
            </form>
          </Dialog>
        );
      }}
    />
  );
};

export default PhoneVerificationDialog;
