import { getLocalStorage, setLocalStorage } from './local-storage';

export const DEFAULT_DOMESTIC_COUNTRY_CODE = 'US';

export const SUPPORTED_COUNTRY_CODES = ['US', 'CA'];

export const isSupportedCountryCode = (countryCode: string) =>
  SUPPORTED_COUNTRY_CODES.includes(countryCode);

export const setDomesticCountryCode = (countryCode: string) => {
  const userSub = getLocalStorage('user-sub');

  if (isSupportedCountryCode(countryCode)) {
    setLocalStorage(`domesticCountryCode-${userSub}`, countryCode);
  }
};

export const getDomesticCountryCode = () => {
  const userSub = getLocalStorage('user-sub');

  if (!userSub) {
    return DEFAULT_DOMESTIC_COUNTRY_CODE;
  }

  return getLocalStorage(`domesticCountryCode-${userSub}`) || DEFAULT_DOMESTIC_COUNTRY_CODE;
};
