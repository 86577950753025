import React from 'react';
import { Dialog } from 'components/common';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    minWidth: 540,
  },
  content: {
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
}));

const HazmatDialog = ({ open, onCancel }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onCancel={onCancel}
      title="Shipment must follow HAZMAT rules"
      showActionBtns={false}
      classes={{
        root: classes.dialogRoot,
        content: classes.content,
      }}>
      <Typography>
        By selecting Hazardous Materials (HAZMAT) shipment, you confirm that you understand and
        comply with all applicable regulations for packaging, labeling, and shipping hazardous
        materials.
      </Typography>
      <Link
        href="https://support.rollo.com/support/solutions/articles/29000048196"
        target="_blank"
        rel="noopener noreferrer">
        Learn More
      </Link>
    </Dialog>
  );
};

export default HazmatDialog;
