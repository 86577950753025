import { CircularProgress } from '@material-ui/core';
import GroupedSelect from 'components/form/GroupedSelect';
import React from 'react';
import { useQueryWithStore } from 'react-admin';

const packageTypesToGroupedOptions = (data, carriers) =>
  data
    ?.filter((i) => !i.provider || carriers?.includes(i.provider))
    ?.reduce((acc, curVal) => {
      const newEntry = { value: curVal.code, label: curVal.label };
      const group = acc.find((i) => i.label === curVal.provider);

      if (!group) {
        acc.push({ label: curVal.provider, options: [{ ...newEntry }] });
      } else {
        group.options = [...group.options, { ...newEntry }];
      }

      return acc;
    }, []);

const PackageTypeSelect = ({ label, name, carriers, country, excludeHazmat = false, ...rest }) => {
  const { loaded, error, data } = useQueryWithStore({
    type: 'get',
    resource: 'enabledPackages',
    payload: { dstCountry: country },
  });

  const filteredData = data?.filter((i) => (excludeHazmat ? !i.code.includes('HAZMAT') : true));

  if (!loaded) {
    return <CircularProgress />;
  }

  if (error) {
    return <p>error</p>;
  }

  const options = packageTypesToGroupedOptions(filteredData, carriers);

  return <GroupedSelect label={label} name={name} options={options} {...rest} />;
};

export default PackageTypeSelect;
