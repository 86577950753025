import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { getResources, useRedirect, useDataProvider } from 'react-admin';
import { NavLink, matchPath } from 'react-router-dom';
import { AppBar as MuiAppBar, Tabs, Toolbar } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { groupBy } from 'lodash';
import UserMenu from './UserMenu';
import MarketRefreshButton from './MarketRefreshButton';
import AppBarTab from './AppBarTab';
import logo from 'rollo.png';
import { openVerification } from 'store/actions/verificationActions';

const styles = (theme) =>
  createStyles({
    root: {
      position: 'relative',
    },

    toolbar: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      boxShadow: theme.shadows[1],
    },

    logo: {
      width: theme.spacing(9),
      marginBottom: 8,
      marginRight: theme.spacing(12),
    },

    tabs: {
      flex: 'auto',
      overflow: 'visible',
    },

    grow: {
      flexGrow: 1,
    },

    buttons: {
      display: 'flex',
      alignItems: 'center',

      '& > *': {
        marginRight: theme.spacing(3.5),
      },
    },

    actionTab: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(4),
      },
    },
  });

const NonTab = ({ className, children }: { className?: string; children?: React.ReactNode }) => (
  <div className={className}>{children}</div>
);

const complexMenus = [
  { label: 'Connections', value: 'connections' },
  // {label: 'Settings', value: 'settings'},
];

const settingTabs = ['shippingPresets', 'warehousePresets'];

const AppBar = ({ classes }) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  // fetch domestic countries on app bar mount
  useEffect(() => {
    dataProvider.getList('domesticCountries');
  }, [dataProvider]);

  const getIndex = (items, menus) => {
    const found = items.find((item) => matchPath(window.location.pathname, item));
    return found ? found.index : false;
  };

  const allItems = [...useSelector((state) => getResources(state), shallowEqual)]
    .filter((resource) => resource.options.menu)
    .map((resource) => {
      const { label, menu, order = 100 } = resource.options;
      return {
        index: complexMenus.find((item) => item.value === menu) ? menu : resource.name,
        path: `/${resource.name}`,
        label,
        menu,
        order,
      };
    })
    .sort((a, b) => a.order - b.order);

  const menus = groupBy(allItems, 'menu');

  let menuIndex = getIndex(allItems, menus);
  if (settingTabs.includes(menuIndex)) menuIndex = false;

  const handleCalculatorClick = (item) => {
    const phoneVerified = JSON.parse(sessionStorage.getItem('phoneVerified') as string);
    if (!phoneVerified && typeof phoneVerified === 'boolean') {
      dispatch(openVerification());
      return;
    }
    redirect(item.path);
  };

  return (
    <MuiAppBar
      className={classes.root}
      color="secondary"
      position="static"
      elevation={0}
      data-test="top-menu">
      <Toolbar className={classes.toolbar}>
        <img className={classes.logo} src={logo} alt="Rollo Logo" />
        <Tabs
          className={classes.tabs}
          value={menuIndex}
          indicatorColor="primary"
          textColor="primary">
          {menus.primary &&
            menus.primary.map((item) => (
              <AppBarTab
                component={NavLink}
                key={item.path}
                value={item.index}
                to={item.path}
                label={item.label}
              />
            ))}
          {complexMenus.map((item) => (
            <AppBarTab
              key={item.value}
              label={item.label}
              value={item.value}
              submenu={menus[item.value]}
            />
          ))}
          <NonTab className={classes.grow} />
          {menus.actions &&
            menus.actions.map((item) => (
              <AppBarTab
                className={classes.actionTab}
                key={item.path}
                value={item.index}
                to={item.path}
                label={item.label}
                onClick={() => handleCalculatorClick(item)}
              />
            ))}
          <NonTab className={classes.buttons}>
            <MarketRefreshButton />
          </NonTab>
          <UserMenu />
          {menus.help &&
            menus.help.map((item) => (
              <AppBarTab
                className={classes.actionTab}
                component={NavLink}
                key={item.path}
                value={item.index}
                to={item.path}
                label={item.label}
              />
            ))}
        </Tabs>
      </Toolbar>
    </MuiAppBar>
  );
};

export default withStyles(styles)(AppBar);
