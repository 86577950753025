import { createContext } from 'react';

export interface Profile {
  id: string;
  [key: string]: any;
}

interface ProfileContextType {
  profile?: any;
  loading: boolean;
  getProfile: () => void;
}

export const ProfileContext = createContext<ProfileContextType>(null!);
