import _isEmpty from 'lodash/isEmpty';
import { Filters, SET_FILTERS } from '../actions/filtersActions';
import moment from 'moment';
interface State {
  [resource: string]: Filters;
}

const defaultFilters = {
  orders: { status: ['ON_HOLD', 'AWAITING_SHIPMENT', 'AWAITING_PAYMENT'], domestic: false },
  scanFormsHistory: {
    date: {
      from: moment().subtract(2, 'days').startOf('day').format('YYYY-MM-DD'),
      to: moment().endOf('day').format('YYYY-MM-DD'),
    },
  },
  scanForms: {
    date: moment().startOf('day').format('YYYY-MM-DD'),
  },
};

const isEmptyFilters = (filters) => {
  if (_isEmpty(filters)) {
    return true;
  }
  const filterKeys = Object.keys(filters);

  if (
    filterKeys.length === 1 &&
    (typeof filters[filterKeys[0]] === 'boolean' || filters[filterKeys[0]] === 0)
  ) {
    return false;
  }

  return filterKeys.length === 1 && _isEmpty(filters[filterKeys[0]]);
};

const INITIAL_STATE = {
  ...defaultFilters,
};

const filtersReducer = (state: State = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_FILTERS:
      return {
        ...state,
        [payload.resource]: isEmptyFilters(payload.filters)
          ? defaultFilters[payload.resource]
          : payload.filters,
      };

    default:
      return state;
  }
};

export default filtersReducer;
