declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

export const LOCAL_STORAGE_KEY_PREFIX = 'rsm1-';

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.log('parsing error on', { value });
    return undefined;
  }
}

const getKey = (key: string): string => {
  return `${LOCAL_STORAGE_KEY_PREFIX}${key}`;
};

export const setLocalStorage = async (key: string, value?: any) => {
  if (typeof window === 'undefined') {
    console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
  }

  try {
    if (value) {
      window.localStorage.setItem(getKey(key), JSON.stringify(value));
    } else {
      window.localStorage.removeItem(getKey(key));
    }

    window.dispatchEvent(new StorageEvent('local-storage', { key: getKey(key) }));
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error);
  }
};

export const getLocalStorage = <T = any | null>(key: string, defaultValue: any = null): T => {
  if (typeof window === 'undefined') {
    return defaultValue;
  }

  try {
    const item = window.localStorage.getItem(getKey(key));
    return item ? (parseJSON(item) as T) : defaultValue;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return defaultValue;
  }
};
