import React, { memo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { Delivery } from 'icons';
import { GroupTitle } from 'components/common/Group';
import { NumberInput, ToggleOptions } from 'components/form';
import {
  composeValidators,
  requireDefined,
  requiredIf,
  positive,
} from 'components/form/validationRules';
import ShipDateInput from 'components/inputs/ShipDateInput';
import { get } from 'lodash';

import { getDomesticCountryCode } from 'services/helpers/countryCode';

const useStyles = makeStyles((theme) => ({
  rightColumnCalculator: {
    paddingTop: theme.spacing(0),
    borderLeft: `1px solid ${theme.palette.background.default}`,
    paddingLeft: theme.spacing(4),
  },
  rightColumnAdjust: {
    paddingTop: theme.spacing(3),
  },
  optionList: {
    display: 'grid',
    gridGap: theme.spacing(4, 4),
    gridTemplateColumns: 'max-content auto',
    marginTop: theme.spacing(3.5),
  },
  optionLabel: {
    lineHeight: `calc(${theme.spacing(4) - 2}px)`,
  },
  optionChoices: {},
  optionChoicesInput: {
    width: '100%',
    maxWidth: 235,
  },
}));

const FormRightColumn = (props) => {
  const { values, form, isAdjustForm } = props;
  const domesticCountryCode = getDomesticCountryCode();

  const classes = useStyles();

  const onChangeInsurance = (form) => (option) => {
    form.batch(() => {
      form.change('options.insurance', option);
      if (!option) {
        form.change('packageInfo[0].insuredValue', null);
      }
    });
  };

  return (
    <Grid
      item
      xs={isAdjustForm ? 12 : 6}
      className={isAdjustForm ? classes.rightColumnAdjust : classes.rightColumnCalculator}>
      <GroupTitle icon={<Delivery />}>Delivery</GroupTitle>
      <div className={classes.optionList}>
        <div className={classes.optionLabel}>Insurance</div>
        <div className={classes.optionChoices}>
          <ToggleOptions
            name="options.insurance"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            validate={requireDefined}
            onChange={onChangeInsurance(form)}
          />
          {get(values, 'options.insurance') && (
            <div className={classes.optionChoicesInput}>
              <NumberInput
                name="packageInfo[0].insuredValue"
                label="Insured Value"
                validate={composeValidators(
                  requiredIf(
                    (value, allValues) =>
                      get(allValues, 'to.address.countryCode') !== domesticCountryCode ||
                      get(allValues, 'options.insurance')
                  ),
                  positive
                )}
              />
            </div>
          )}
        </div>
        <div className={classes.optionLabel}>Signature</div>
        <div className={classes.optionChoices}>
          <ToggleOptions
            name="options.signature"
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            validate={requireDefined}
          />
        </div>
        <div className={classes.optionLabel}>Shipping Date</div>
        <div className={classes.optionChoices}>
          <ShipDateInput
            name="shipmentDate"
            validate={requireDefined}
            isAdjustForm={isAdjustForm}
            warehouse={values?.from}
          />
        </div>
      </div>
    </Grid>
  );
};

export default memo(FormRightColumn);
