import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useDataProvider } from 'react-admin';
import { Form } from 'react-final-form';
import { get, merge } from 'lodash';

import { FORM_ERROR } from 'final-form';
import { List, ListItem, ListItemIcon, ListItemText, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { Group, GroupContent, GroupItem } from 'components/common/Group';
import { CountriesSelect, FormCard, FormErrorLabel, Input, StateSelect } from 'components/form';
import { Dialog } from 'components/common';
import {
  required,
  address,
  requiredAddress,
  composeValidators,
  maxLength,
  canadaZipCode,
  usZipCode,
} from 'components/form/validationRules';
import SubmitButton from 'components/buttons/SubmitButton';
import AddressDistributor from 'components/form/AddressDistributor';
import DeleteActionButton from './DeleteActionButton';
import EditActionButton from './EditActionButton';
import {
  getDomesticCountryCode,
  DEFAULT_DOMESTIC_COUNTRY_CODE,
} from 'services/helpers/countryCode';

import { presetToValidationRequest, suggestionToPresetRequest } from 'services/helpers/converters';
import addressToString from 'services/helpers/addressToString';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(5),
  },
  content: {
    maxHeight: '250px',
  },
}));

class AddressValidationError extends Error {
  constructor(
    message: string,
    public suggestions?: object[] | undefined
  ) {
    super(message);
  }
}

const ValidationErrorDialog = ({
  save,
  values,
  setValidationError,
  suggestions,
  validationError,
  setSubmitError,
}) => {
  const classes = useStyles();

  const { string, ...newValues } = values;
  const [open, setOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setOpen(!!validationError);
  }, [validationError]);

  const onListElemClick = async (suggestion) => {
    try {
      setSubmitting(true);
      await save({
        name: newValues.name,
        ...suggestionToPresetRequest(suggestion),
        phone: newValues.phone,
      });
    } catch (error) {
      setSubmitError(error.message);
    } finally {
      setSubmitting(false);
      setOpen(false);
    }
  };

  const onConfirm = async (e, newValues) => {
    try {
      setSubmitting(true);
      e.preventDefault();
      return await save(newValues);
    } catch (error) {
      setSubmitError(error.message);
    } finally {
      setSubmitting(false);
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      title="Address Check"
      confirmText="Proceed"
      onConfirm={(e) => onConfirm(e, newValues)}
      onCancel={() => setValidationError(undefined)}
      dialogContentProps={{
        classes: {
          root: classes.content,
        },
      }}
      submitting={submitting}>
      Please&nbsp;
      {suggestions?.length && 'click on a suggested address below or '}
      press PROCEED to continue.
      {suggestions && (
        <List>
          {get(validationError, 'suggestions', []).map((suggestion) => (
            <ListItem
              key={suggestion.addressLine1}
              button
              onClick={() => onListElemClick(suggestion)}
              disabled={submitting}>
              <ListItemIcon>
                <LocationOnOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={addressToString(suggestion, {
                  paths: { state: 'stateProvince.code' },
                })}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Dialog>
  );
};

const WarehousePresetForm = ({
  basePath,
  resource,
  record,
  save,
  redirect,
  onSubmitted = () => {},
  onBackClick,
  xs,
}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [validationError, setValidationError] = useState<object | undefined>(undefined);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [showParser, setShowParser] = useState(false);

  const suggestions = get(validationError, 'suggestions');

  const states = useSelector((state) => state.admin.resources.states.data, shallowEqual);

  const domesticCountryCode = getDomesticCountryCode();

  const handleSubmitForm = (orgValues) => {
    setSubmitError(null);
    const { string, ...values } = orgValues;
    return dataProvider
      .post('validateAddress', {
        data: presetToValidationRequest({
          ...values,
          stateOrProvince: states[values.stateOrProvinceId]
            ? states[values.stateOrProvinceId]
            : {
                ...values.stateOrProvince,
                code: values.stateOrProvinceId,
              },
        }),
      })
      .then(async ({ data: { result, suggestions } }) => {
        if (result === 'SUCCESS') {
          await save(values);
          await onSubmitted();
        } else {
          if (!suggestions.length) {
            await save(values);
            await onSubmitted();
            return;
          }

          throw new AddressValidationError('Invalid address', suggestions);
        }
      })
      .catch((error) => {
        if (error?.suggestions?.length) {
          setValidationError({ ...error });
        }

        return { [FORM_ERROR]: error.message };
      });
  };

  if (!record || !domesticCountryCode) {
    return null;
  }

  const initialValues = merge(
    { stateOrProvince: { country: { code: domesticCountryCode } } },
    record
  );

  const zipCodeValidator = composeValidators(
    required,
    initialValues.stateOrProvince.country.code === DEFAULT_DOMESTIC_COUNTRY_CODE
      ? usZipCode
      : canadaZipCode
  );

  const onSave = async (values) => {
    await save(values);
    await onSubmitted();
  };

  return (
    <FormCard
      xs={xs}
      onBackClick={onBackClick}
      basePath={basePath}
      title={record.id ? 'Edit Ship From Address' : 'Create Ship From Address'}
      redirect={redirect}
      actions={<DeleteActionButton basePath={basePath} resource={resource} record={record} />}>
      <Form
        initialValues={initialValues}
        keepDirtyOnReinitialize
        onSubmit={handleSubmitForm}
        render={({ handleSubmit, submitting, values, dirty, ...renderProps }) => {
          const isCountryUS = get(values, 'stateOrProvince.country.code') === 'US';
          return (
            <form onSubmit={handleSubmit}>
              <ValidationErrorDialog
                {...{
                  save: onSave,
                  values,
                  setValidationError,
                  suggestions,
                  validationError,
                  redirect,
                  setSubmitError,
                }}
              />
              <Group>
                <GroupContent>
                  {showParser && (
                    <GroupItem>
                      <Input
                        name="string"
                        label="Paste or type US address here..."
                        multiline={true}
                      />
                      <AddressDistributor
                        field="string"
                        targets={[
                          'name',
                          'addressLine1',
                          'addressLine2',
                          'city',
                          'stateOrProvinceId',
                          'postalCode',
                        ]}
                      />
                    </GroupItem>
                  )}
                  {showParser && (
                    <GroupItem>
                      <Button
                        size="small"
                        className={classes.button}
                        endIcon={<ArrowDropUpIcon />}
                        onClick={() => setShowParser(!showParser)}>
                        Paste US Address
                      </Button>
                    </GroupItem>
                  )}
                  <GroupItem>
                    <Input
                      name="name"
                      label="Ship From Address Name"
                      inputProps={{
                        helperText: 'This will appear on your shipping labels as the sender',
                      }}
                      validate={composeValidators(required, maxLength(35))}
                    />
                  </GroupItem>
                  {!showParser && isCountryUS && (
                    <GroupItem>
                      <Button
                        size="small"
                        className={classes.button}
                        endIcon={<ArrowDropDownIcon />}
                        onClick={() => setShowParser(!showParser)}>
                        Paste US Address
                      </Button>
                    </GroupItem>
                  )}
                  <GroupItem wide>
                    <Input
                      name="addressLine1"
                      label="Street Address 1"
                      validate={requiredAddress}
                    />
                  </GroupItem>
                  <GroupItem wide>
                    <Input
                      name="addressLine2"
                      label="Street Address 2 (optional)"
                      validate={address}
                    />
                  </GroupItem>
                  <GroupItem>
                    <Input name="city" label="City / Town" validate={required} />
                  </GroupItem>
                  <GroupItem>
                    <StateSelect
                      name="stateOrProvinceId"
                      label="State"
                      countryCode={get(values, 'stateOrProvince.country.code')}
                      valueMapper={(state) => state?.id}
                      validate={required}
                    />
                  </GroupItem>
                  <GroupItem>
                    <Input name="postalCode" label="ZIP" validate={zipCodeValidator} />
                  </GroupItem>
                  <GroupItem>
                    <CountriesSelect
                      disabled
                      name="stateOrProvince.country.code"
                      label="Country"
                      validate={required}
                    />
                  </GroupItem>
                  <GroupItem>
                    <Input name="phone" label="Phone number" validate={required} />
                  </GroupItem>
                </GroupContent>
              </Group>
              <Group>
                <FormErrorLabel
                  {...renderProps}
                  submitError={submitError ?? renderProps.submitError}
                  preventedErrorLabels={['Invalid address']}
                />
              </Group>
              {!record.id ? (
                <SubmitButton
                  color="primary"
                  size="large"
                  label="Save"
                  submitting={submitting}
                  fullWidth
                />
              ) : (
                <EditActionButton
                  record={record}
                  handleSubmit={handleSubmit}
                  submitting={submitting}
                  values={values}
                  dirty={dirty}
                />
              )}
            </form>
          );
        }}
      />
    </FormCard>
  );
};

export default WarehousePresetForm;
