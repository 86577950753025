import React, { cloneElement, useCallback } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Dialog } from 'components/common';
import SearchInput from 'components/list/SearchInput';
import { Filters } from 'icons';
import { debounce, set } from 'lodash';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { setFilters as actionSetFilters } from 'store/actions/filtersActions';
import FilterChips from './FilterChips';

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'white',
    '& button': {
      color: 'inherit',
    },
  },
  form: {
    width: '100%',
    paddingRight: theme.spacing(),
    '& .hide-filter, & .MuiInput-input, & .MuiFormLabel-root, & .MuiSelect-icon': {
      color: 'white',
    },
    '& .MuiSelect-select': {
      minWidth: 50,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiChip-label': {
      color: theme.palette.primary.main,
    },
    '& .MuiChip-avatar': {
      width: 24,
      height: 24,
    },
  },
  dialogRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  resetButton: {
    fontSize: 12,
  },
  filterChips: {
    margin: 0,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
}));

const DialogTitle = ({ onFilterReset }) => {
  const classes = useStyles();

  return (
    <>
      Filters
      <Button color="primary" onClick={onFilterReset} className={classes.resetButton}>
        Reset
      </Button>
    </>
  );
};

const FilterButton = (props) => (
  <Button variant="contained" {...props}>
    <Filters />
  </Button>
);

const Filter = (props) => {
  const dispatch = useDispatch();
  const {
    resource,
    children,
    filterValues,
    initialFilters,
    setFilters,
    basePath,
    btnProps = {},
    classes: classesOverride = {},
    withSearch,
    enableLiveSearch = true,
    filterDefaultValues = {},
    syncWithStore = true,
    loading,
    searchKey = 'q',
  } = props;

  const classes = { ...useStyles(), ...classesOverride };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleSubmitClose = useCallback(() => setOpen(false), [setOpen]);
  const filters = React.Children.toArray(children);

  const handleClose = (form) => () => {
    setOpen(false);
    form.reset();
  };

  const mergedFilterValues = { ...filterDefaultValues, ...filterValues };
  const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues({
    initialValues: mergedFilterValues,
    filters,
  });

  const buttonProps = { onClick: handleClickOpen, ...btnProps };

  const onFilterReset = (form) => {
    dispatch(
      actionSetFilters({
        resource,
        filters: { ...(initialFilters || filterDefaultValues) },
      })
    );
    form.reset();
    setFilters(initialFilters || filterDefaultValues);
  };

  const debouncedSubmit = debounce((value, submitCallback) => {
    submitCallback(value);
  }, 1000);

  const onLiveSearch = useCallback((e, submitCallback) => {
    debouncedSubmit({ [e.target.name]: e.target.value }, submitCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      onSubmit={(values) => {
        setFilters(values);
        if (syncWithStore) {
          dispatch(
            actionSetFilters({
              resource,
              filters: values,
            })
          );
        }
        handleSubmitClose();
      }}
      initialValues={mergedInitialValuesWithDefaultValues}
      render={({ handleSubmit, form }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            {withSearch && (
              <Field
                key={searchKey}
                name={searchKey}
                label="Search"
                filterButton={<FilterButton {...buttonProps} />}
                render={(inputProps) => (
                  <SearchInput
                    {...inputProps}
                    {...{
                      filterValues: mergedFilterValues,
                      filters,
                      setFilters,
                      resource,
                    }}
                    loading={loading}
                    onSearchKeyUp={(e) => (enableLiveSearch ? onLiveSearch(e, handleSubmit) : null)}
                  />
                )}
              />
            )}
            {!withSearch && (
              <div className={classes.filterContainer}>
                <FilterButton {...buttonProps} />
                <FilterChips
                  filterValues={mergedFilterValues}
                  classesOverride={classes.filterChips}
                  filters={filters}
                  setFilters={setFilters}
                  resource={resource}
                />
              </div>
            )}
            <Dialog
              open={open}
              title={<DialogTitle onFilterReset={() => onFilterReset(form)} />}
              confirmText="Apply"
              onCancel={handleClose(form)}
              onConfirm={handleSubmit}>
              <div className={classes.dialogRoot}>
                {filters.map((filter) => (
                  <Field
                    key={filter.props.source}
                    name={filter.props.source}
                    label={filter.props.label}
                    render={(inputProps) =>
                      cloneElement(filter, {
                        ...inputProps,
                        basePath,
                        resource,
                        record: {},
                      })
                    }
                  />
                ))}
              </div>
            </Dialog>
          </form>
        );
      }}
    />
  );
};

const mergeInitialValuesWithDefaultValues = ({ initialValues, filters }) => ({
  ...filters
    .filter((filterElement) => filterElement.props.alwaysOn && filterElement.props.defaultValue)
    .reduce(
      (acc, filterElement) =>
        set({ ...acc }, filterElement.props.source, filterElement.props.defaultValue),
      {}
    ),
  ...initialValues,
});

const getFilterIfNoButton = (props) => (props.context === 'button' ? null : <Filter {...props} />);

export default getFilterIfNoButton;
