import { getDomesticCountryCode } from 'services/helpers/countryCode';

const isInternationalShipment = (shippingData, returnLabel) => {
  const domesticCountryCode = getDomesticCountryCode();

  if (!returnLabel) {
    const {
      from: { countryCode: fromCountryCode },
      to: {
        address: { countryCode: toCountryCode },
      },
    } = shippingData;
    return (
      !!fromCountryCode &&
      !!toCountryCode &&
      (fromCountryCode !== domesticCountryCode || toCountryCode !== domesticCountryCode)
    );
  }
  const {
    from: {
      address: { countryCode: fromCountryCode },
    },
    to: { countryCode: toCountryCode },
  } = shippingData;

  return (
    !!fromCountryCode &&
    !!toCountryCode &&
    (fromCountryCode !== domesticCountryCode || toCountryCode !== domesticCountryCode)
  );
};

export default isInternationalShipment;
