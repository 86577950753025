import { getDomesticCountryCode } from 'services/helpers/countryCode';

const MILITARY_STATES = ['AE', 'AA', 'AP'];

const domesticCountryCode = getDomesticCountryCode();

export const isMilitaryAddress = (address) => {
  if (domesticCountryCode !== 'US') {
    return false;
  }

  return MILITARY_STATES?.some(
    (item) => item === address?.stateProvinceCode || item === address?.stateOrProvince?.code
  );
};
