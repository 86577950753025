import React, { PropsWithChildren, useCallback, useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';

import CircularProgress from '@material-ui/core/CircularProgress';
import { ProfileContext, Profile } from 'app/contexts/profile-context';
import { setLocalStorage } from 'services/helpers/local-storage';
import { getDomesticCountryCode, setDomesticCountryCode } from 'services/helpers/countryCode';
import { pick } from 'lodash';
import { Box } from '@material-ui/core';

const getCurrentUser = async (params): Promise<{ data: Profile } | undefined> => {
  try {
    const { attributes } = await Auth.currentAuthenticatedUser({
      bypassCache: params.bypassCache === undefined ? true : params.bypassCache,
    });

    if (!attributes) {
      throw new Error('The user is not authenticated');
    }

    return {
      data: {
        ...pick(attributes, ['given_name', 'family_name', 'email', 'phone_number', 'sub']),
        id: params.id,
      },
    };
  } catch (error) {
    if (
      error === 'The user is not authenticated' ||
      error.message === 'The user is not authenticated'
    ) {
      localStorage.removeItem('verificationClosed');

      Auth.signOut();

      // Remove items from local storage that include 'CognitoIdentityServiceProvider' in their name
      Object.keys(window.localStorage).forEach((key) => {
        if (key.includes('CognitoIdentityServiceProvider')) {
          localStorage.removeItem(key);
        }
      });

      window.location.href = '/';
    }
  }
};

export const ProfileProvider = ({ children }: PropsWithChildren<{}>) => {
  const [profile, setProfile] = useState<Profile | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const getProfile = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getCurrentUser({
        id: 'profile',
      });

      // set user sub to local storage to be used by countryCode helper
      setLocalStorage('user-sub', result?.data?.sub);

      setProfile(result?.data);
    } catch (error) {
      console.error('Failed to fetch profile:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!profile) {
      getProfile();
    }
  }, [getProfile, profile]);

  useEffect(() => {
    if (profile) {
      const countryCode = getDomesticCountryCode();
      setDomesticCountryCode(countryCode);
    }
  }, [profile]);

  useEffect(() => {
    return () => {
      setLocalStorage('user-sub');
    };
  }, []);

  if (loading || !profile) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ProfileContext.Provider
      value={{
        profile,
        getProfile,
        loading,
      }}>
      {children}
    </ProfileContext.Provider>
  );
};
