import React, { lazy } from 'react';
import { withRouter } from 'react-router';
import compose from 'recompose/compose';
import { useLocation } from 'react-router-dom';

import { createMuiTheme, Zoom } from '@material-ui/core';
import { ThemeProvider, createStyles, withStyles } from '@material-ui/styles';

import CustomNotification from 'components/layout/CustomNotification';
import { ProfileProvider } from 'app/providers/ProfileProvider';
import Freshworks from 'track/Freshworks';
import AppBar from './AppBar';
import Footer from './Footer';
import Dialogs from './dialogs';
import PhoneVerificationBanner from 'components/phoneVerification/PhoneVerificationBanner';

const MaintenancePage = lazy(() => import('pages/maintenance'));

const isWebKit154 =
  typeof navigator !== 'undefined' &&
  /^((?!chrome|android).)*(safari|mobile)/i.test(navigator.userAgent) &&
  /(os |version\/)15(.|_)4/i.test(navigator.userAgent);

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#6C13F9',
      light: '#C6C9E0',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#F2F4FF',
      contrastText: '#969AB3',
    },
    divider: '#EFEFEF',
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
    background: {
      default: '#FAFAFC',
    },
  },

  typography: {
    fontFamily: 'Lato, sans-serif',

    h6: {
      fontWeight: 'bold',
    },

    overline: {
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '24px',
    },
  },

  overrides: {
    MuiButton: {
      root: {
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '16px',
        borderRadius: 22.5,
        padding: '8px 16px',
        boxShadow: 'none',
        whiteSpace: 'nowrap',
      },
      contained: {
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.03);',
        backgroundColor: '#F2F4FF',
        color: '#969AB3',
        '&:hover': {
          boxShadow: '0 1px 3px 0 rgba(0,0,0,0.03);',
        },
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#C6C9E0',
        },
      },
      sizeLarge: {
        padding: 18,
        fontSize: 16,
        lineHeight: '20px',
        borderRadius: 28,
      },
      sizeSmall: {
        fontSize: 12,
        lineHeight: '14px',
        borderRadius: 22.5,
        padding: '4px 16px',
      },
      outlined: {
        padding: '8px 16px',
        '&:not($outlinedPrimary):not($outlinedSecondary)': {
          borderColor: '#C6C9E0',
          color: '#969AB3',
        },
      },
    },

    MuiTabs: {
      scrollButtons: {
        color: '#969AB3',
      },
    },

    MuiTab: {
      root: {
        fontWeight: 'bold',
        textTransform: 'none',
        padding: '24px 0',

        '&:not(:last-child)': {
          marginRight: 48,
        },

        '@media (min-width: 0)': {
          fontSize: 16,
          lineHeight: '20px',
          minWidth: 'auto',
        },
      },

      textColorPrimary: {
        color: '#969AB3',
      },
    },

    MuiMenuItem: {
      root: {
        minHeight: '40px !important',
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 'bold',
        color: '#969AB3',
      },
    },

    MuiPaper: {
      rounded: {
        borderRadius: 16,
      },
    },

    MuiTableCell: {
      root: {
        fontSize: 16,
        borderBottomColor: '#EFEFEF',
      },

      head: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#969AB3',
        padding: '16px!important',
      },

      body: {
        '.MuiTableRow-root:last-child > &': {
          borderBottom: 'none',
        },
      },
    },

    MuiTablePagination: {
      root: {
        color: '#C6C9E0',
      },

      toolbar: {
        borderTop: '1px solid #EFEFEF',
      },

      input: {
        color: '#6C13F9',
      },
    },

    MuiInput: {
      input: {
        padding: '8px 0 13px',
      },

      underline: {
        '&$focused': {
          '&:after': {
            transform: 'scaleX(1) translateX(0)',
          },
        },
      },
    },

    MuiCheckbox: {
      root: {
        color: '#969AB3',
      },
    },

    MuiFormHelperText: {
      root: {
        color: '#969AB3',
      },
    },

    MuiList: {
      root: {
        transform: 'translateZ(0)',
      },
    },
  },
  props: isWebKit154
    ? {
        MuiPopover: {
          TransitionComponent: Zoom,
        },
        MuiTooltip: {
          TransitionComponent: Zoom,
        },
      }
    : {},
});

theme.shadows[1] = '0 1px 3px 0 rgba(0,0,0,0.03);';
theme.shadows[8] = '0 1px 24px 0 rgba(0,0,0,0.1);';

const styles = (theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      minWidth: 'fit-content',
      width: '100%',
    },
    content: {
      position: 'relative',
      padding: theme.spacing(3, 4),
      flexGrow: 1,
    },
  });

const Layout = ({ classes, children }) => {
  const location = useLocation();
  return location.pathname === '/maintenance' ? (
    <>
      <MaintenancePage />
      <Freshworks />
    </>
  ) : (
    <div className={classes.root}>
      <AppBar />
      <PhoneVerificationBanner />
      <main className={classes.content}>{children}</main>
      <Footer />
      <CustomNotification />
      <Dialogs />
      <Freshworks />
    </div>
  );
};

const EnhancedLayout = compose(withRouter, withStyles(styles))(Layout);

const LayoutWithTheme = (props) => (
  <ThemeProvider theme={theme}>
    <ProfileProvider>
      <EnhancedLayout {...props} />
    </ProfileProvider>
  </ThemeProvider>
);

export default LayoutWithTheme;
